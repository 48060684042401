<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col class="col-12">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('academic_year')">
                            <b-form-input
                                v-model="form.academic_year"
                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col class="col-12">
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semester-type-selectbox
                                v-model="form.semester"
                                :validate-error="errors[0]"
                            ></semester-type-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"
import {ValidationProvider, ValidationObserver} from "vee-validate"
import SemesterService from "@/services/SemesterService";

export default {
    components: {
        SemesterTypeSelectbox,
        ValidationProvider, ValidationObserver
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                semester: null,
                academic_year: null
            }
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            SemesterService.get(id)
                           .then((response) => {
                               this.form = response.data.data;
                           })
                           .catch((error) => {
                               if (error.data.message) {
                                   this.$toast.error(this.$t("api." + error.data.message));
                               }
                           });
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                SemesterService.update(this.formId, this.form)
                               .then((response) => {
                                   this.$toast.success(this.$t("api." + response.data.message));
                                   this.$emit("updateFormSuccess")
                               })
                               .catch(error => {
                                   this.showErrors(error, this.$refs.formModalValidate)
                               })
                               .finally(() => {
                                   this.formLoading = false
                               });
            }
        }
    }
}
</script>
