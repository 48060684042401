<template>
    <div>
            <b-row>
                <b-col class="col-12">
                        <b-form-group :label="$t('academic_year')">
                            <div class="label-as-input">{{ isNotNullValue(formData.academic_year) }}</div>
                        </b-form-group>
                </b-col>
                <b-col class="col-12">
                        <b-form-group :label="$t('semester')">
                            <div class="label-as-input">{{ isNotNullValue(formData.semester) }}</div>
                        </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
    </div>
</template>

<script>

import SemesterService from "@/services/SemesterService";

export default {
    components: {
        SemesterService
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {}
        }
    },

    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            SemesterService.get(id)
                           .then((response) => {
                               this.formData = response.data.data;
                           })
                           .catch((error) => {
                               if (error.data.message) {
                                   this.$toast.error(this.$t("api." + error.data.message));
                               }
                           });
        },

    }
}
</script>
