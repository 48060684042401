<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('semester_title')"
                        :isNewButton="checkPermission('semester_store')"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('semester_title')"
                              :isNewButton="checkPermission('semester_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            />

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('semester_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('score_information') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>

            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"  v-if="checkPermission('semester_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"  v-if="checkPermission('semester_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from "@/layouts/AppLayout";
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    // Component
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import CommonModal from "@/components/elements/CommonModal";
    // Services
    import SemesterService from "@/services/SemesterService";

    // Page
    import CreateForm from "./CreateFrom";
    import UpdateForm from "./UpdateForm";
    import ShowForm from "./ShowForm"
    // Other
    import qs from "qs";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        AcademicYearsSelectbox,
        ShowForm,
        CreateForm,
        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t("semester"),
        };
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'semester_show',
                                callback: (row) => {
                                    this.showForm(row.code);
                                },
                                show: () => {
                                    return this.checkPermission('semester_update') ? false : true
                                }
                            },
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: 'semester_update',
                                callback: (row) => {
                                    this.loadData(row.id);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: 'semester_update',
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("academic_year"),
                        field: "academic_year",
                        sortable: true,
                    },
                    {
                        label: this.$t("semester_text"),
                        field: ("semester_text"),
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        // Show
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SemesterService.getAll(config)
                                  .then((response) => {
                                      this.datatable.rows = response.data.data;
                                      this.datatable.total = response.data.pagination.total;
                                  })
                                  .finally(() => {
                                      this.datatable.isLoading = false;
                                  });
        },
        loadData(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        showErrors(error) {
            if (error.status == 422) {
                if (error.data.errors.semester) {
                    this.$refs.storeForm.errors.semester.push(error.data.errors.semester[0]);
                }
                if (error.data.errors.academic_year) {
                    this.$refs.storeForm.errors.academic_year.push(error.data.errors.academic_year[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                SemesterService.deleteSemester(id)
                               .then((response) => {
                                   this.filter();
                                   this.$toast.success(this.$t("api." + response.data.message));
                               })
                               .catch((error) => {
                                   this.$toast.error(this.$t("api." + error.data.message));
                               });
            });
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
    },
};
</script>
